#topbar {
	background-color: #005D16;
	height: 200px;
	width: 100%;
	text-align: center;
}

.divbar {
	height: 10px;
	background-color: #6BAC5F;
}

.green {
	color: #00BD00;
}

#body {
	background-color: #C6C6C6;
	width: 80%;
	margin: auto;
	text-align: center;
}

#footer {
	background-color: #005D16;
	height: 200px;
}

.url {
	float: left;
}

.social {
	float: right;
}